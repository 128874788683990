/** Hides the outer shapes - for this style we only need one **/
svg.a9s-annotationlayer .a9s-selection .a9s-outer,
svg.a9s-annotationlayer .a9s-annotation .a9s-outer {
  visibility: hidden;
}

svg.a9s-annotationlayer .a9s-handle .a9s-handle-outer {
  visibility: hidden;
}

.a9s-annotation .a9s-inner:hover {
  stroke: #fff000;
  // fill: #fff0001a !important;
}

// .a9s-annotation.editable .a9s-inner {
//   stroke: #fff000;
//   fill: #fff0001a!important;
// }

.a9s-handle .a9s-handle-inner {
  stroke: #bbb;
}

.r6o-editor {
  width: 400px;
  z-index: 1;
  .r6o-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
    .r6o-btn {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      background-color: #572373;
      border: 1px solid #572373;
      color: #fff;
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 1.75;
      min-width: 64px;
      padding: 4px 10px;
      border-radius: 4px;
      box-shadow:
        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      &.outline {
        background-color: transparent;
        box-shadow: none;
        border: 1px solid rgba(87, 35, 115, 0.5);
        color: #572373;
        &:hover {
          background-color: rgba(87, 35, 115, 0.04);
        }
      }
      &.delete-annotation {
        color: #572373;
      }
      &.analysis {
        border: none;
        min-width: 32px;
        justify-content: center;
        position: absolute;
        top: 7px;
        left: 45px;
      }
      &.analysis-graph {
        border: none;
        min-width: 32px;
        justify-content: center;
        position: absolute;
        top: 7px;
        left: 85px;
      }
      &.analysis-graph-comp {
        border: none;
        min-width: 32px;
        justify-content: center;
        position: absolute;
        top: 7px;
        left: 125px;
      }
      &.analysis-mcd-data {
        border: none;
        min-width: 32px;
        justify-content: center;
        position: absolute;
        top: 7px;
        left: 165px;
      }
      &:last-child:disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12) !important;
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12) !important;
      }
      &:hover {
        background-color: rgb(60, 24, 80);
      }
    }
  }

  .analysis-style {
    border: none !important;
    background-color: #fff !important;
    color: #572373 !important;
    border-radius: 100% !important;
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    &:hover {
      color: #fff !important;
      background-color: #572373 !important;
    }
  }

  .r6o-editor-inner {
    padding: 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  .widget {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    label {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      font-size: 0.875rem;
      line-height: 1.43;
      font-weight: 600;
      letter-spacing: 0.01071em;
      color: #4a4a4a;
      margin: 0;
      padding: 0;
    }

    p {
      padding: 10px;
      border-bottom: 1px solid #e5e5e5;
      line-height: normal;
      margin: 0;
    }

    .widget-input {
      font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
      width: 100%;
      padding: 10px;
      font-size: 0.875rem;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      outline: none;
      &:focus,
      &:hover {
        border: 1px solid #572373;
      }
      &:disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
      }
    }

    .widget-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
      padding-top: 10px;
      margin: 0 0 5px 0;
      > label {
        background-color: #fff;
        position: absolute;
        top: 0px;
        left: 8px;
        z-index: 10;
        padding: 0 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(133% - 32px);
      }
    }

    .dropdown-widget {
      position: relative;
      .widget-input {
        margin-bottom: 5px;
      }
      .dropdown-input-wrapper {
        padding: 8px 36px 8px 10px;
        margin: 0;
        flex-wrap: wrap;
        font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4375em;
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        cursor: text;
        display: inline-flex;
        align-items: center;
        width: 100%;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
        &:focus-within {
          border-color: #572373;
          border-width: 1px;
        }
        &.disabled {
          cursor: not-allowed;
          .tag-wrapper {
            background-color: #f5f5f5;
            cursor: not-allowed;
            .tag {
              cursor: not-allowed;
            }
            .close {
              cursor: not-allowed;
            }
          }
          .clear-filter-button {
            cursor: not-allowed;
          }
          input {
            cursor: not-allowed;
          }
        }

        .tag-wrapper {
          margin: 2px 5px 2px 0px;
          max-width: calc(100% - 4px);
          position: relative;
          cursor: pointer;
          user-select: none;
          font-size: 0.8125rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          color: rgba(0, 0, 0, 0.87);
          background-color: rgba(0, 0, 0, 0.08);
          border-radius: 16px;
          transition:
            background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          outline: 0;
          text-decoration: none;
          border: 0;
          padding: 0;
          vertical-align: middle;
          box-sizing: border-box;
          .tag {
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 8px;
            padding-right: 8px;
            white-space: nowrap;
            cursor: pointer;
            user-select: none;
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            font-size: 0.8125rem;
            color: rgba(0, 0, 0, 0.87);
          }
          .close {
            color: #fff;
            background-color: #aeaeae;
            font-size: 16px;
            cursor: pointer;
            margin: 0 5px 0 -6px;
            margin-right: 4px;
            margin-left: -4px;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        input {
          padding: 2.5px 4px 2.5px 0px;
          width: 0;
          min-width: 30px;
          flex-grow: 1;
          text-overflow: ellipsis;
          opacity: 1;
          letter-spacing: inherit;
          color: currentColor;
          border: 0;
          box-sizing: content-box;
          background: none;
          height: 1.4375em;
          font-size: 0.875rem;
          margin: 0;
          display: block;
          &:focus {
            outline: none;
          }
        }
        .clear-filter-button {
          right: 4px;
          position: absolute;
          top: calc(50% - 14px);
          font-size: 1rem;
          line-height: 1.4375em;
          color: rgba(0, 0, 0, 0.87);
          box-sizing: border-box;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          transition:
            background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          outline: 0;
          text-decoration: none;
          border: 0;
          padding: 0;
          vertical-align: middle;
          background-color: transparent;
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
      .dropdown {
        position: absolute;
        display: none;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        // border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 5px;
        z-index: 20;
        box-shadow:
          0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        .dropdown-content {
          padding: 5px 0px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 0px;
          margin: 0;
          max-height: 150px;
          min-height: 32px;
          overflow-y: auto;
          .dropdown-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 5px;
            list-style: none;
            font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
            width: 100%;
            padding: 5px;
            cursor: pointer;
            outline: none;
            &.no-checkbox {
              padding: 5px 20px;
            }
            &:hover {
              background-color: rgba(0, 0, 0, 0.04);
            }
            &.active {
              background-color: rgba(87, 35, 115, 0.12);
            }
            .checkbox-container {
              display: block;
              position: relative;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              padding-left: 30px;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                &:checked ~ .checkmark {
                  background-color: #572373;
                  border: none;
                }
              }
              .checkmark {
                position: absolute;
                top: 7.5px;
                left: 7.5px;
                height: 15px;
                width: 15px;
                background-color: transparent;
                border: 1.5px solid #666;
                border-radius: 2px;
                &:after {
                  content: '';
                  position: absolute;
                  display: none;
                  left: 5px;
                  top: 1px;
                  width: 5px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 1.5px 1.5px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
              }
              input:checked ~ .checkmark:after {
                display: block;
              }
              &:hover {
                background-color: rgba(87, 35, 115, 0.04);
              }
            }
            label {
              width: 100%;
              cursor: pointer;
              margin: 0;
              padding: 5px 0px;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.43;
              letter-spacing: 0.01071em;
              color: rgba(0, 0, 0, 0.87);
            }
          }
        }
        &.show {
          display: block;
        }
      }
    }
  }
}
