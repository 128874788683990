.notification-header {
    .MuiBadge-standard {
        border: 1px solid;
        top: 15px;
        right: 14px;
        width: 25px;
        border-radius: 50%;
        height: 25px;
    }

    &_icon {
        transition: transform 0.2s ease;
        transform-origin: top center;

        &:hover {
            animation: bellShake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        }

        &.animate {
            animation: bellShake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        }
    }
}

@keyframes bellShake {
    0% {
        transform: rotate(0);
    }

    15% {
        transform: rotate(10deg);
    }

    30% {
        transform: rotate(-10deg);
    }

    45% {
        transform: rotate(8deg);
    }

    60% {
        transform: rotate(-8deg);
    }

    75% {
        transform: rotate(4deg);
    }

    85% {
        transform: rotate(-4deg);
    }

    92% {
        transform: rotate(2deg);
    }

    100% {
        transform: rotate(0);
    }
}