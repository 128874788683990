@import 'vendors/index.scss';
@import 'custom.scss';

.custom-color-picker {
  .MuiInputBase-root {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MuiInputAdornment-root {
    margin: 0;
  }
  input {
    display: none;
  }
  fieldset {
    display: none;
  }
}

.custom-color-picker-input {
  .MuiInputBase-root {
    height: 100%;
    display: flex;
    padding: 0 7px;
    align-items: center;
    justify-content: center;
  }
  input {
    padding: 7px 7px;
    font-size: 16px;
  }
}

.disabled {
  td,
  tr {
    opacity: 0.38;
  }
}

.tool-btn {
  &.active {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 0;
  }
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: linear-gradient(180deg, #572373 10%, #8f305d 90%);
  padding: 4px;
  border: 4px solid transparent;
  background-clip: padding-box;
  overflow: auto;
}

.annotation-title {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-input {
  padding: 6px 8px !important;
  font-size: 14px !important;
}

.custom-dialog-content {
  overflow-y: visible !important;
}

.analysis-graph-container {
  height: 100%;
  width: 100%;
  display: grid;
  // grid-template-rows: 1fr 1fr;
  // gap: 0px;
  //Align the two child elements (buttons) horizontally
  justify-content: center;
}

.string-network {
  width: 100%;
  height: 90%;
  margin-bottom: 1rem;
  margin-left: 10%;
  // overflow: scroll;
  // position: relative;
}

// .string-network > * {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }