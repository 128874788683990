$root-class: mention-input;
$primary-color: rgba(87, 35, 115);
$black: rgba(0, 0, 0, 0.87);

.#{$root-class} {
  box-sizing: border-box;
  color: $black;
  line-height: 1.3375em;
  height: 40px;
  width: 100%;
  border-radius: 4px;

  &--multiLine {
    height: 100%;
  }

  &--rounded {
    border-radius: 16px;
  }

  &__control {
    border-radius: inherit;
    box-sizing: border-box;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: inherit;
    height: 100%;
    color: $black;
    position: relative;
    cursor: text;
    display: inline-flex;
    width: 100%;
    padding: 8.5px 14px;
  }

  &__highlighter {
    font-weight: normal;

    strong {
      background-color: rgb(206, 228, 229, 0.3);
      color: #002680;
    }
  }

  &__input {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: inherit;
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    box-sizing: content-box;
    background: none;
    height: 100%;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 8.5px 14px;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    position: absolute;
    outline: none;

    &:hover {
      border-color: rgba(87, 35, 115, 0.87);
    }

    &:focus {
      border-color: $primary-color;
      outline: 1px solid $primary-color;
    }
  }

  &__suggestions {
    &__list {
      max-height: 360px;
      overflow-y: auto;
    }
  }

  &__text-highlighter {
    background-color: rgb(206, 228, 229, 0.3);
    color: #002680;

    &:hover {
      cursor: default;
    }
  }

}
