@keyframes highlightPrimary {
  0% {
    background-color: transparent;
    height: 100%;
    width: 100%;
  }
  50% {
    background-color: rgb(0, 38, 128, 0.2);
  }
  99% {
    height: 100%;
    width: 100%;
  }
  100% {
    background-color: rgb(0, 38, 128, 0);
    height: 0;
  }
}

.animate-highlight-primary {
  animation: highlightPrimary 1s 0s ease-in-out;
}
